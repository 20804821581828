import kf from "../../img/1366/kf_5ker.webp";
import cit from "../../img/1366/cit_5ker.webp";
import map from "../../img/graphic_assets/MapSimple@600x.png";
const Otker = ({ t, lang }) => {
  return (
    <div className="otker-page">
      <div className="otker-hero">
        <div className="desk-wrap">
          <div className="hero-inner">
            <div className="text-wrapper">
              <h1>
                <p>Sétahajós járatainkra</p>
                <p className="big">50%</p>
                <p className="small">Kedvezmény</p>
              </h1>
              <h3>V. kerületi lakosoknak</h3>
              <p className="expl">
                <span>*</span>A kedvezmény kizárólag a helyszínen váltott jegyekre érvényes.
              </p>
              <div className="img-wrap">
                <img src={cit} alt={t("main1.gallery.alts.fallback")} />
              </div>
              <div
                className="more-wrap"
                onClick={() => {
                  const ele = document.getElementById("content");
                  ele.scrollIntoView({ behavior: "smooth" });
                }}>
                <p>Tudj meg többet!</p>
                <div className="icon">
                  <div className="tri"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="img-wrap desk first">
            <img src={cit} alt={t("main1.gallery.alts.fallback")} />
          </div>
          <div className="img-wrap desk">
            <img src={kf} alt={t("main1.gallery.alts.fallback")} />
          </div>
        </div>
        <div className="hero-icons"></div>
      </div>
      <div className="otker-content" id="content">
        <div className="header">
          <div className="img-wrap">
            <img src={map} alt="Map of Budapest" />
          </div>
          <div className="text-wrap">
            <div className="header-inner">
              {" "}
              <p>5. kerületi lakos vagy?</p>
              <p className="big">VÁSÁROLD MEG JEGYEDET A HELYSZÍNEN</p>
              <p className="gold">50%-os kedvezménnyel!</p>
            </div>
            <p className="second">
              Váltsd meg jegyedet a Duna Cruises járataira a DOCK 10 kikötőnkben, mutasd fel
              lakcímkártyád és élj az 50%-os kedvezménnyel bármely járatunkra!
            </p>
          </div>{" "}
        </div>
        <div className="faq-items-wrap">
          <div className="faq-item">
            <h4>Kikre vonatkozik a kedvezmény?</h4>
            <p>
              Minden 5. kerületi lakos igénybe veheti 50%-os kedvezményünket. Ehhez mindössze a
              lakcímkártya bemutatására lesz szükség a helyszínen való jegyvásárlás során.
            </p>
          </div>
          <div className="faq-item">
            <h4>Hol vásárolhatom meg a jegyem?</h4>
            <p>
              Az 50%-os kedvezmény igénybevételéhez a helyszínen (Budapest, Jane Haining rkp. 10.
              DOCK 10) kell megváltanod a jegyedet nyitvatartási időben (12:00-21:00). Online
              vásárlás esetén nem tudjuk biztosítani a kedvezményt.
            </p>
          </div>
          <div className="faq-item">
            <h4>Mire lesz szükségem?</h4>
            <p>
              Mindenképpen hozd magaddal a lakcímkártyádat, mert annak felmutatása ellenében tudjuk
              csak biztosítani az 50%-os kedvezményt.
            </p>
          </div>
          <div className="faq-item">
            <h4>Milyen járatokra vonatkozik a kedvezmény?</h4>
            <p>Mindegyik járatunkra.</p>
          </div>
          <div className="faq-item">
            <h4>Hol találom a kikötőt?</h4>
            <p>Budapest, Jane Haining rkp. 10. Keresd a DOCK 10 feliratot!</p>
          </div>
          <div className="faq-item">
            <h4>MEDDIG ÉRVÉNYES A KEDVEZMÉNY?</h4>
            <p>
              A kedvezmény visszavonásig érvényes. Esetleges változásokról előzetesen tájékoztatunk
              a weboldalunkon.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Otker;
