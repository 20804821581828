import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import languageReducer from "./Language/language.reducer";
import appearReducer from "./Appear/appear.reducer";
import cartReducer from "./Cart/cart.reducer";
import tripReducer from "./Trip/trip.reducer";
import orderReducer from "./Order/order.reducer";
import userReducer from "./User/user.reducer";
import affReducer from "./Affiliate/affiliate.reducer";
import modalReducer from "./Modal/modal.reducer";
import dateReducer from "./Date/date.reducer";
import couponReducer from "./Coupon/coupon.reducer";

export const rootReducer = combineReducers({
  languageData: languageReducer,
  appearData: appearReducer,
  cartData: cartReducer,
  tripData: tripReducer,
  orderId: orderReducer,
  user: userReducer,
  aff: affReducer,
  isModal: modalReducer,
  date: dateReducer,
  couponReducer,
});

const configStorage = {
  key: "root",
  storage,
};

export default persistReducer(configStorage, rootReducer);
